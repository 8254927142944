<template>
    <v-app>
        <!-- <Navbar :isMobile="$store.getters.isMobile" :productsInCart="productsInCart"/> -->

        <v-container v-if="order">
            <v-row>
                <v-col cols="12">
                    <h2>Detalhes do Pedido <span style="color: #606060">#BEE{{ (order.order.id + "").padStart(6, "0") }}</span></h2>
                </v-col>
                <v-col cols="12" md="8">
                    <div class="card-infos">
                        <v-row>
                            <v-col>
                                <h3>Informações pessoais</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Nome completo" v-model="order.order.name" readonly>
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="CPF" v-model="order.order.cpf" readonly>
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="date" class="input-default" placeholder="Data de Nascimento" v-model="order.order.birth" readonly>
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Telefone" v-model="order.order.phone" v-mask="['(##) ####-####', '(##) #####-####']" readonly>
                            </v-col>
                            <v-col cols="12" md="12">
                                <input type="email" class="input-default" placeholder="Email" v-model="order.order.email" readonly>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="card-infos">
                        <v-row>
                            <v-col>
                                <h3>Endereço de entrega</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <input type="text" class="input-default" placeholder="CEP" @keyup="searchCep()" ref="cep" v-model="order.order.zipCode" v-mask="['#####-###']" readonly>
                            </v-col>
                            <v-col cols="8" md="9">
                                <input type="text" class="input-default" placeholder="Endereço" ref="street" v-model="order.order.address" readonly>
                            </v-col>
                            <v-col cols="4" md="3">
                                <input type="number" class="input-default" placeholder="N°" ref="number" v-model="order.order.number" readonly>
                            </v-col>
                            <v-col cols="12" md="12">
                                <input type="text" class="input-default" placeholder="Complemento" ref="complement" v-model="order.order.neighborhood" readonly>
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Cidade" v-model="order.order.city" readonly>
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Estado" v-model="order.order.state" readonly>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col>
                    <div class="summary">
                        <v-row>
                            <v-col cols="12">
                                <div class="products">
                                    <v-row class="product" v-for="(p, index) in products" :key="index">
                                        <v-col cols="4" md="3" class="image">
                                            <img :src="p.image" style="width: 100%;" alt="">
                                        </v-col>
                                        <v-col class="desc">
                                            <v-row>
                                                <v-col cols="12" class="name">
                                                    <h3 style="font-size: 15px">{{p.quantity}} x {{p.name}} ({{ p.variant }})</h3>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Resumo</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>Subtotal</h4>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h4>{{ parseFloat(order.order.subtotal).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>Frete</h4>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h4>{{ parseFloat(order.order.shipping).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Total</h3>
                            </v-col>
                            <v-col style="text-align: right;" v-if="order.charge_provider == 'efi'">
                                <h3>{{ parseFloat(order.order.total).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h3>
                                <h4 v-if="order.charge.payment.method == 'credit_card'">{{ order.charge.payment.credit_card.installments }}x {{ (order.charge.payment.credit_card.installment_value / 100).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h4>
                            </v-col>
                            <v-col style="text-align: right;" v-if="order.charge_provider == 'mercadopago'">
                                <h3>{{ parseFloat(order.order.total).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row v-if="order.charge_provider == 'mercadopago'">
                            <v-col>
                                <div id="statusScreenBrick_container"></div>
                            </v-col>
                        </v-row>
                        <v-row v-if="order.charge_provider == 'efi'">
                            <v-col>
                                <h3 v-if="order.charge_provider == 'efi'">Dados Pagamento ({{ order.charge.payment.method == 'banking_billet' ? 'PIX' : "CRÉDITO" }})</h3>
                                <h3 v-if="order.charge_provider == 'mercadopago'">Dados Pagamento (CRÉDITO)</h3>
                            </v-col>
                        </v-row>
                        <v-row v-if="order.charge.status != 'waiting' & order.charge_provider == 'efi'">
                            <v-col>
                                <h3>Status</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3 v-if="order.charge.status == 'paid'" style="color: green">Pago</h3>
                                <h3 v-if="order.charge.status == 'approved'" style="color: green">Pago</h3>
                                <h3 v-if="order.charge.status == 'settled'" style="color: green">Pago</h3>
                                <h3 v-if="order.charge.status == 'unpaid'" style="color: red">Recusado</h3>
                                <h3 v-if="order.charge.status == 'canceled'" style="color: red">Cancelado</h3>
                                <h3 v-if="order.charge.status == 'waiting'">Aguardando pagamento</h3>
                                <h3 v-if="order.charge.status == 'pending'" style="color: #f70293">Aguardando</h3>
                                <h3 v-if="order.charge.status == 'authorized'" style="color: #f70293">Aguardando Aprovação</h3>
                            </v-col>
                        </v-row>
                        <div v-if="order.charge_provider == 'efi'">
                            <v-row v-if="order.charge.payment.method == 'banking_billet' && order.charge.status == 'waiting'">
                                <v-col cols="12">
                                    <h4 style="text-align: center;">ESCANEIE O QRCODE PIX</h4>
                                    <img :src="order.charge.payment.banking_billet.pix.qrcode_image" alt="">
                                </v-col>
                                <v-col cols="12">
                                    <h4 style="text-align: center;">OU</h4>
                                    <button class="btn-default" @click="copyToClipboard(order.charge.payment.banking_billet.pix.qrcode)">Copiar PIX</button>
                                </v-col>
                            </v-row>
                            <v-row v-if="order.charge.payment.method == 'credit_card'">
                                <v-col>
                                    <h3>Cartão de Crédito</h3>
                                </v-col>
                            </v-row>
                            <v-row v-if="order.charge.payment.method == 'credit_card'">
                                <v-col cols="5">
                                    <h5>Nome do titular</h5>
                                </v-col>
                                <v-col style="text-align: right;">
                                    <h5>{{ order.charge.customer.name }}</h5> 
                                </v-col>
                            </v-row>
                            <v-row v-if="order.charge.payment.method == 'credit_card'">
                                <v-col>
                                    <h4>Número do cartão</h4>
                                </v-col>
                                <v-col style="text-align: right;">
                                    <h4>{{ order.charge.payment.credit_card.mask }}</h4>
                                </v-col>
                            </v-row>
                        </div>
                        



                    </div>
                </v-col>
            </v-row>
        </v-container>

        <Footer :isMobile="$store.getters.isMobile" />

        <Loading v-if="!order" />
        
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {mask} from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})


// import { loadMercadoPago } from "@mercadopago/sdk-js";

// await loadMercadoPago();
// var mp
// if(process.env.VUE_APP_SANDBOX == 'true'){
//   console.log('sandbox')
//   mp = new window.MercadoPago("TEST-765bccb4-c17f-4263-a805-ccc611fed751");
// }else{
//   console.log('produção')
//   mp = new window.MercadoPago("APP_USR-f7a71a7a-eb66-421e-8700-bc3419730c14");
// }

export default {
    name: 'OrderPage',
    metaInfo() {
        return {
        title: `${this.storeData.name} - Pedido`
        }
    },
    directives: {
        mask
    },
    components: {
        Notice,
        Navbar,
        Footer,
        Loading,
        FontAwesomeIcon
    },
    data(){
        return {
            order: null,
            products: [],
        }
    },
    async created(){
        await this.init()
    },
    methods: {
        async init(){
            this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
            await this.getOrderDetails()
            await this.verifyQntCart()
            if(this.order.charge_provider == 'mercadopago'){
                await this.initMP()
            }
        },
        async initMP(){
            let app = this;
            const mp = new MercadoPago(process.env.VUE_APP_SANDBOX == 'true' ? "TEST-404fc680-3f95-483f-96a9-a4b61ad4d039" : "APP_USR-af3c16da-7be1-4858-9018-8e06774cc5a8");
            const bricksBuilder = mp.bricks();
            const renderStatusScreenBrick = async (bricksBuilder) => {
            const settings = {
                initialization: {
                    paymentId: app.order.charge.id,
                },
                customization: {
                    visual: {
                        hideStatusDetails: true,
                        hideTransactionDate: true,
                        style: {
                        theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
                        }
                    },
                    backUrls: {
                        'error': '',
                        'return': ''
                    }
                },
                callbacks: {
                    onReady: () => {
                    },
                    onError: (error) => {
                    },
                },
            };
            window.statusScreenBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
            };
            renderStatusScreenBrick(bricksBuilder);
        },
        async verifyQntCart(){
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
            this.productsInCart > 0 ? this.btnDisabled = false : this.btnDisabled = true
        },
        async getOrderDetails(){
            try {
                let order = await api.get(`/orders/${this.$route.params.token}`)
                this.order = order.data
                this.order.order.cpf = `${this.order.order.cpf.split('.')[0]}.XXX.XXX-${this.order.order.cpf.split('-')[1]}`
                this.order.order.birth = new Date(this.order.order.birth).toLocaleDateString('pt-BR').toString().split('/').reverse().join('-')
                if(this.order.charge_provider == 'efi'){
                    if(this.order.charge.payment.method == 'banking_billet' && this.order.charge.status == 'waiting'){
                        this.verifyPaymentPix(this.order.charge.charge_id)
                    }
                }
                this.products = this.order.order.products

                // for (const i of this.products) {
                //     i.variantSelected = JSON.parse(i.product.variants).filter(x => x.id == i.variantId)[0]
                // }
            } catch (error) {
                console.log(error)
            }
        },
        copyToClipboard(text){
            navigator.clipboard.writeText(text)
            alert('Copiado para a área de transferência')
        },
        async getCharge(charge_id){
            try {
            let response = await api.get(`/order/charge/${charge_id}`, await this.$store.getters.getConfig)
            return response.data
            } catch (error) {
            console.log(error)
            }
        },
        async verifyPaymentPix(charge_id){
            let interval = setInterval(async () => {
            let charge = await this.getCharge(charge_id)
            console.log(charge.status)
            if(charge.status == 'paid' || charge.status == 'settled'){
                clearInterval(interval)
                alert('Pagamento aprovado!')
                location.reload()
            }
            }, 5000)
        }
    }
}
</script>

<style scoped>
    *{
        font-family: "Exo", sans-serif;
    }
    ::placeholder {
        color: #00000066;
        opacity: 1; /* Firefox */
    }
    hr{
        border: 1px solid #0000001A;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    h2{
        font-size: 2.5rem;
        font-weight: 700;
    }

    .btn-default{
        width: 100%;
        background-color: #000;
        color: #fff;
        border-radius: 62px;
        padding: 10px;
    }

    .btn-default.disabled{
        background-color: #00000066;
    }

    .input-default{
        width: 100%;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }


    .input-qnt{
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
        margin-right: 10px;
    }
    .input-qnt .remove img, .input-qnt .add img{
        width: 20px;
        cursor: pointer;
    }

    .input-qnt .qnt{
        text-align: center;
        font-size: 1.5rem;
    }

    .input-qnt .add img{
        float: right;
        margin-top: 8px;
        margin-right: 10px;
    }
    .input-qnt .remove img{
        float: left;
        margin-top: 8px;
        margin-left: 10px;
    }



    .card-infos{
        border-radius: 20px;
        border: 1px solid #0000001A;
        padding: 20px;
        margin-bottom: 20px;
    }

    .summary{
        border-radius: 20px;
        border: 1px solid #0000001A;
        padding: 20px;
    }
</style>